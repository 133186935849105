<template>
  <div class="blog-post-details-container">
    <div v-if="post" class="blog-post-details">
      <h1 class="post-title">{{ post.title }}</h1>
      <!-- Render blocks of content -->
      <div v-for="(block, index) in contentBlocks" :key="index" class="content-block">
        <p v-if="block.type === 'text'" v-html="block.content"></p>
        <figure v-else-if="block.type === 'image'" class="image-container">
          <img :src="block.content.src" :alt="block.content.alt" class="post-image"/>
          <figcaption>{{ block.content.caption }}</figcaption>
        </figure>
      </div>
      <!-- Embed YouTube video if available -->
      <iframe v-if="post.youtubeVideoId" width="560" height="315" :src="youtubeEmbedUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <!-- Display GitHub link if available -->
      <a v-if="post.githubRepoUrl" :href="post.githubRepoUrl" target="_blank" class="github-link">View on GitHub</a>
      <!-- Display Hugging Face link if available -->
      <a v-if="post.huggingFaceUrl" :href="post.huggingFaceUrl" target="_blank" class="hugging-link">View on Hugging Face</a>
    </div>
    <div v-else class="loading">
      Loading post...
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'BlogPostDetails',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      post: null,
    };
  },
  computed: {
    ...mapGetters(['getPostById']),
    youtubeEmbedUrl() {
      if (!this.post || !this.post.youtubeVideoId) return '';
      return `https://www.youtube.com/embed/${this.post.youtubeVideoId}`;
    },

    contentBlocks() {
      if (!this.post) return [];

      const blocks = [];
      const contentSections = this.post.content.split(/\[\[Image\d+\]\]/);

      contentSections.forEach((section, index) => {
        if (section) {
          blocks.push({ type: 'text', content: section.trim() });
        }
        const imageKey = `Image${index + 1}`;
        if (this.post.images && this.post.images[imageKey]) {
          blocks.push({ type: 'image', content: this.post.images[imageKey] });
        }
      });

      return blocks;
    },
  },
  mounted() {
    this.fetchPost();
  },
  methods: {
    fetchPost() {
      // Use the Vuex getter to find the post by id
      this.post = this.getPostById(parseInt(this.id, 10));
    },
  },
};


</script>

  
<style scoped>
.blog-post-details-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100vw;

}

.blog-post-details {
  max-width: 800px;
  width: 100vw;
  background: #fff;
  padding: 20px;
  margin-top: 90px; /* Adjusted margin to align with your layout */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Enhanced shadow for depth */
  border-radius: 20px; /* Rounded corners for consistency with your theme */
}

.post-title {
  margin-bottom: 20px;
  color: #1976D2; /* Color scheme alignment */
  font-size: 36px; /* Consistent font sizing */
  font-weight: bold;
  text-align: center; /* Centered title for a more balanced appearance */
}
.post-image {
  width: 70%;
  height: auto;
  margin-top: 20px;
}
.image-caption {
  text-align: center;
  margin-top: 5px;
  font-style: italic;
}
.post-content {
  color: #666; /* Harmonized text color */
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify; /* Improved readability */
}

.video-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  padding-bottom: 56.25%; /* Aspect ratio for videos */
  position: relative;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px; /* Consistent border-radius */
}

.github-link {
  display: block;
  background-color: #1976D2; /* Theme color for consistency */
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition */
  margin-top: 15px;
  margin-bottom: 30px; /* Spacing adjustments */
}

.github-link:hover {
  background-color: #2196F3; /* Slightly lighter on hover */
  transform: scale(1.05); /* Interactive feel */
}

.loading {
  color: #333;
  font-size: 20px;
  text-align: center; /* Centered loading text */
}
</style>
