<template>
    <div id="app">
      <div id="animated-text">
        We are your <span :style="{ color: currentColor }">{{ dynamicText }}</span>
      </div>
    </div>
  </template>
  
  <script>
export default {
  data() {
    return {
      baseText: "We are your ", // Static part of the text
      phrases: ["AI engineers", "Data scientists", "Business partners"],
      dynamicText: "", // Dynamic part of the text that changes
      colors: ["#00C107", "#1c8dff", "#FFC107"], // Example colors for each dynamic phrase
      currentColor: "", // To store the current color for the dynamic text
      phraseIndex: 0,
      isAdding: true,


    };
  },
  mounted() {
    this.currentColor = this.colors[0]; // Initialize color for the first phrase
    this.animateText();
  },
  methods: {
    animateText() {
      let charIndex = this.isAdding ? this.dynamicText.length : this.dynamicText.length - 1;
      const currentPhrase = this.phrases[this.phraseIndex];
      const updateDelay = this.isAdding ? 120 : 60; // Speed of adding vs removing characters

      if (this.isAdding) {
        if (charIndex < currentPhrase.length) {
          this.dynamicText = currentPhrase.substring(0, charIndex + 1);
          setTimeout(this.animateText, updateDelay);
        } else {
          // Pause at the end of the dynamic phrase before changing
          setTimeout(() => {
            this.isAdding = false;
            this.animateText();
          }, 2000);
        }
      } else {
        if (charIndex >= 0) {
          this.dynamicText = currentPhrase.substring(0, charIndex);
          setTimeout(this.animateText, updateDelay);
        } else {
          // Move to the next phrase or loop back to the start
          this.phraseIndex = (this.phraseIndex + 1) % this.phrases.length;
          this.isAdding = true;
          // Update color for the new dynamic phrase
          this.currentColor = this.colors[this.phraseIndex % this.colors.length];
          this.animateText();
        }
      }
    },
  },
};

  </script>
  
  <style>
#app {
  width: 100%;
  min-height: 100vh; /* Allows the container to expand beyond the viewport height if needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center vertically */
}

#animated-text {
  font-size: 32px; /* Larger font size */
  font-weight: bold;
  color: grey; /* Default color for the static part */

  text-align: center; /* Ensure text is centered */
}

/* Ensure the body or main container allows for scrolling */
body, html {
  margin: 0;
  padding: 0;
  height: auto; /* Ensure the body can expand as needed */
}


.dynamic-text {
  color: var(--currentColor); /* Use CSS variable for dynamic color changes */
}

.canvas-container {
position: relative;
width: 100%;
height: 80vh;
}

.text-overlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
}

/* Enhance the animated text appearance */
#animated-text {
font-size: 3rem; /* Increased font size for better visibility */
font-weight: bold;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Aesthetic font */
color: #ffffff; /* Initial color, but let's focus on shadow for contrast */
text-shadow:
  -2px -2px 0 #031F41,
  2px -2px 0 #031F41,
  -2px 2px 0 #031F41,
  2px 2px 0 #031F41; /* White contrast shadow for better legibility */
}

/* Ensure text color transitions are smooth */
#animated-text span {
transition: color 0.5s ease-in-out; /* Smooth transition for color change */
}

</style>


  