<template>
  <nav class="navbar">
    <li>
      <router-link to="/" @click="trackNavigationEvent('Home', '/')" @mouseover="trackHoverEvent('Home')">
        <img src="/icons/logo2.png" class="logo" :class="{'logo-hidden': isMenuActive}" alt="Logo">
      </router-link>
    </li>
    <button @click="toggleMenuWithTracking" @mouseover="trackHoverEvent('Toggle Navigation Button')" class="menu-toggle" aria-label="Toggle navigation">
      <span class="hamburger"></span>
    </button>
    <ul :class="{'menu-active': isMenuActive}">
      <li class="dropdown" @mouseover="openDropdown" @mouseleave="closeDropdown">
        <span @click="toggleDropdown" @mouseover.stop="trackHoverEvent('Services')">Solutions</span>
        <ul v-show="isDropdownActive" class="dropdown-content">
          <li><router-link to="/#services" @click="trackNavigationEvent('Services', '/services')" @mouseover="trackHoverEvent('services')">Services</router-link></li>
          <li><router-link to="/products" @click="trackNavigationEvent('Products', '/products')" @mouseover="trackHoverEvent('products')">Products</router-link></li>
        </ul>
      </li>
      <li><router-link to="/#about-us" @click="trackNavigationEvent('About Us', '/#about-us')" @mouseover="trackHoverEvent('About Us')">About Us</router-link></li>
      <li><router-link to="/meeting" @click="trackNavigationEvent('Schedule a Meet', '/meeting')" @mouseover="trackHoverEvent('Schedule a Meet')">Schedule a meeting</router-link></li>
      <li><router-link to="/blog" @click="trackNavigationEvent('Blog', '/blog')" @mouseover="trackHoverEvent('Blog')">Blog</router-link></li>
      <li><router-link to="/contact-us" @click="trackNavigationEvent('Contact Us', '/contact-us')" @mouseover="trackHoverEvent('Contact Us')">Contact Us</router-link></li>
    </ul>
  </nav>
</template>

<script>
import mixpanel from 'mixpanel-browser';

export default {
  name: 'AppNavbar',
  data() {
    return {
      isMenuActive: false,
      isDropdownActive: false, // New dropdown state

    };
  },
  methods: {
    toggleMenuWithTracking() {
      this.trackSpecificEvent('Click', 'Toggle Menu', 'Toogle Menu');
      this.toggleMenu();
    },

    trackSpecificEvent(action, detail, name) {
      mixpanel.track('Navbar Interaction '+action+ ' '+name, {
        'action': action,
        'detail': detail
      });
    },
    trackNavigationEvent(name, route) {
      this.trackSpecificEvent('Click', `${name} - ${route}`, `${name}`);
      this.closeMenu();
    },
    trackHoverEvent(itemName) {
      this.trackSpecificEvent('Hover', itemName, itemName);
    },
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    toggleDropdown() {
      // This method now simply toggles the dropdown.
      this.isDropdownActive = !this.isDropdownActive;
    },
    openDropdown() {
      // Explicitly open the dropdown
      this.isDropdownActive = true;
    },
    closeDropdown() {
      // Explicitly close the dropdown
      this.isDropdownActive = false;
    },
    // Remember to modify the closeMenu method to also hide the dropdown
    closeMenu() {
      this.isMenuActive = false;
      this.isDropdownActive = false; // Hide dropdown when menu is closed
    },
    scrollToSection(sectionId) {
      this.closeMenu();
      this.$nextTick(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #FFFFFF;
  color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

body {
  padding-top: 60px;
}

.menu-toggle {
  display: none;
  cursor: pointer;
  border: none;
  background: none;
}

.hamburger, .hamburger::before, .hamburger::after {
  display: block;
  background-color: #ffffff;
  height: 2px;
  width: 25px;
  margin: 5px 0;
  transition: all 0.3s ease-in-out;
}

ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

ul li {
  margin: 0 15px;
}

ul li a {
  color: #000000;
  text-decoration: none;
}

.logo {
  max-width: 120px; /* Adjusted for responsiveness */
  height: auto;
  width: auto;
}

.navbar a:hover, .footer a:hover {
color: #0775fc;
}

ul li a[href*="/contact-us"] {
  background-color: #0f6ada;
  color: white; /* White text */
  padding: 10px 20px; /* Padding around the text */
  border-radius: 20px; /* Rounded borders */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

/* Hover effect for the Contact Us button */
ul li a[href*="/contact-us"]:hover {
  background-color: #031F41; /* Darker shade of green */
}

.dropdown {
  position: relative;
  display: inline-block;
  border-radius: 30px;
}

.dropdown-content {
  display: none; /* Initially hidden, shown on hover or click */
  position: absolute;
  left: 0; /* Align to the left edge of the dropdown trigger */
  top: 100%; /* Position it just below the dropdown trigger */
  background-color: #FFFFFF;
  min-width: 160px; /* Adjust based on content */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 30px;
}

.dropdown-content li {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 30px;
}

.dropdown-content li:hover {
  background-color: #ffffff;}
/* Ensure navbar text is visible */
.navbar, .dropdown span {
  color: #FFFFFF; /* Adjust as needed to make it visible against your navbar's background */
}
.menu-active{
  border-radius: 10px;

}
/* Dropdown Hover Effect */
.dropdown:hover .dropdown-content {
  display: block; /* Show dropdown when hovering over .dropdown */
}

/* Adjustments for dropdown link colors for visibility */
.dropdown-content li a {
  color: #000000; /* White text for visibility against the dropdown background */
}

/* Style for the Services dropdown trigger */
.dropdown > span {
  cursor: pointer;
  color: #000000; /* Ensure this color is visible against your navbar's background */

}
@media (max-width:768px) {
.menu-toggle {
display: block;
}

.hamburger::before, .hamburger::after {
content: "";
position: absolute;
width: 100%;
height: 2px;
background-color: #ffffff;
transition: all 0.3s ease-in-out;
}

.hamburger::before {
transform: translateY(0px);
}

.hamburger::after {
transform: translateY(4px);
}

.hamburger, .hamburger::before, .hamburger::after {
display: block;
background-color: #000000;
height: 2px;
width: 25px;
transition: all 0.3s ease-in-out;
}

ul {
position: absolute;
top: 60px;
left: 0;
flex-direction: column;
align-items: center;
width: 100%;
display: none;
padding: 0;
background-color: #FFFFFF;
z-index: 999;
}

ul.menu-active {
display: flex;
}
.logo-hidden {
    display: none; /* Hides the logo when menu is active */
  }

ul li {
margin: 15px 0; /* Increase spacing for touch targets */
}

.navbar a:hover, .footer a:hover {
color: #0775fc;
}
}
</style>
