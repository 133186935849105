<template>
  <v-app>
    <header>
      <Navbar />
    </header>
    <v-main>
  <div id="app">
    <router-view :key="$route.fullPath"></router-view>
  </div>
</v-main>
    <footer>
      <Footer />
    </footer>
  </v-app>
</template>

<script>
// Importing components used in this layout
import Navbar from './components/AppNavbar.vue';
import Footer from './components/AppFooter.vue';
import mixpanel from 'mixpanel-browser';
import {v4 as uuidv4} from 'uuid';


export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
  },
  mounted() {
    // Initializing Mixpanel with your project's token
    mixpanel.init('4653053e83d9ccb2a97f21b94205612a', {
      debug: true,
      persistence: 'localStorage'
    });

    // Ideally, replace 'USER_ID' with a dynamic value representing the current user's ID
    mixpanel.identify(uuidv4());

    // Automatically track page view on component mount
    mixpanel.track_pageview();

    // Update the document title
    document.title = "Deep Vance AI";
    this.sumNumbers();

  },
  methods: {
    async sumNumbers() {
      fetch('https://backend-nuestra-web-3541c3056b46.herokuapp.com/sum', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ a: 5, b: 7 }),
      })
      .then(response => response.json())
      .then(data => console.log("Sum result:", data.result))
      .catch(error => console.error("Error fetching data:", error));
    },

    trackSignUpEvent() {
      // Call this method where the sign-up event actually happens (e.g., after successful sign-up)
      mixpanel.track('Landing', {
        'Description': 'landed on main web'
      });
    }
  }
};
</script>

  <style>
  /* Global styles */

  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #ffffff;
    color: #333;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #333;
  }

  .container {
    max-width: 1200px;
    margin: auto;
    padding: 0 20px;
  }

  /* Button styles */
  .btn {
    display: inline-block;
    background-color: #007bff;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
  }

  .btn:hover {
    background-color: #0056b3;
  }

  /* Improved Container Visibility */
.container {
  background: #ffffff; /* White background for containers */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Adding shadow for depth */
  border-radius: 8px; /* Rounded corners */
}

/* Add transitions to all elements for smooth hover effects */
* {
  transition: all 0.3s ease;
}

  </style>

