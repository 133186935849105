import { createRouter, createWebHistory, nextTick } from 'vue-router';
import ViewHome from '../views/ViewHome.vue';
import ContactUs from '../views/ContactUs.vue';
import ViewBlog from '../views/ViewBlog.vue';
import Meeting from '../components/MeetingScheduler.vue';
import BlogPostDetails from '../components/BlogPostDetails.vue';
import ProductsView from '@/views/ProductsView.vue';
import NomenclatureView from '@/views/NomenclatureView.vue';
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: ViewHome,
    },
    {
      path: '/about-us',
      name: 'about',
      component: ViewHome,
    },
    {
      path: '/products',
      name: 'products',
      component: ProductsView,
    },
    {
      path: '/services',
      name: 'services',
      component: ViewHome,
    },
    {
      path: '/contact-us',
      name: 'ContactUs',
      component: ContactUs,
    },
    {
      path: '/meeting',
      name: 'meeting',
      component: Meeting,
    },
    {
      path: '/blog',
      name: 'ViewBlog',
      component: ViewBlog,
    },
    {
      path: '/product/nomenclature',
      name: 'nomenclature',
      component: NomenclatureView,
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/',
    },
    {
      path: '/post/:id',
      name: 'post-details',
      component: BlogPostDetails,
      props: true,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    const navbarOffset = 70; // Example: 70px navbar height

    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop - navbarOffset,
        behavior: 'smooth',
      });
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },

});

// After each route change, check if we need to scroll to a specific section
router.afterEach(to => {
  nextTick(() => {
    if (to.path === '/services') {
      const servicesSection = document.getElementById('services');
      if (servicesSection) {
        servicesSection.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (to.path === '/about-us') {
      const aboutUsSection = document.getElementById('about-us');
      if (aboutUsSection) {
        aboutUsSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  });
});

router.beforeEach((to, from, next) => {
  console.log(`Navigating from ${from.path} to ${to.path}`);
  next();
});

export default router;
