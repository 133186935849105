<template>
  <div class="about-us container">
    <section id="team-introduction">
      <h2>About Our Team</h2>
      <p class="team-description">We are a team of dedicated AI experts committed to delivering cutting-edge solutions and transforming businesses. Our diverse expertise and innovative thinking are the pillars of our success.</p>
    </section>
    <div class="team-members" :style="gridStyle">
      <div class="team-member" v-for="member in teamMembers" :key="member.id" @mouseover="hoverMemberWithTracking(member.id)" @mouseleave="leaveMemberWithTracking(member.id)">
        <img :src="member.photo" :alt="member.name" class="member-photo">
        <div class="member-info">
          <h3>{{ member.name }}</h3>
          <p class="member-role">{{ member.role }}</p>
          <p class="member-description" v-if="expandedMember !== member.id">{{ member.shortDescription }}</p>
          <p class="member-description" v-if="expandedMember === member.id">{{ member.description }}</p>
          <button class="read-more" @click="toggleDescriptionWithTracking(member.id)">
            {{ expandedMember === member.id ? 'Read Less' : 'Read More' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import mixpanel from 'mixpanel-browser';

export default {
  name: 'AboutUs',
  data() {
    return {
      columnsUsed: 3,
      teamMembers: [
      // {
      //   id: 1,
      //   name: 'Juan Wisznia',
      //   role: 'LLM Expert',
      //   photo: '/images/membersTeam/juanw.jpg', // Place john-doe.jpg in /public/images/
      //   description: 'Juan is a seasoned expert in Large Language Models (LLM), known for his skill in fine-tuning models and developing advanced RAG applications. With a background in consulting at Accenture, he combines technical expertise with leadership, guiding teams to deliver cutting-edge AI solutions.'
      // },
      {
        id: 2,
        name: 'Cecilia Bolaños',
        role: 'Machine Learning Engineer',
        photo: '/images/membersTeam/chechu.jpg', // Place john-doe.jpg in /public/images/
        description: 'Cecilia is an inventive Machine Learning Engineer with a flair for crafting creative AI solutions. Specializing in solving complex challenges, her work enhances efficiency and innovation across sectors such as healthcare and finance.'
      },
      {
        id: 3,
        name: 'Axel Fridman',
        role: 'AI Specialist',
        photo: '/images/membersTeam/axel.jpg', // Place john-doe.jpg in /public/images/
        description: 'Axel is a leading expert in artificial intelligence, specializing in machine learning and data analytics. With a master in Data Science, Axel has helped transform businesses by implementing advanced AI solutions.'
      },
      // {
      //   id: 4,
      //   name: 'Andres Cotton',
      //   role: 'Prompt Engineer',
      //   photo: '/images/membersTeam/andy.jpeg', // Place john-doe.jpg in /public/images/
      //   description: 'Andrés is a Prompt Engineer & Linguist that specializes in Large Language Models. He has built generative AI solutions for companies in the US, Europe & Israel.'
      // },
      {
        id: 5,
        name: 'Micaela Hirsch',
        role: 'Data Scientist',
        photo: '/images/membersTeam/mica.jpg', // Place john-doe.jpg in /public/images/
        description: 'Micaela is a committed Data Scientist with a strong background in AI, specializing in Natural Language Processing (NLP). She applies her machine learning expertise to drive business improvements and develop innovative solutions.'
      },
      {
        id: 6,
        name: 'Rocco Di Tella',
        role: 'Data Scientist',
        photo: '/images/membersTeam/rocco.jpeg', // Place john-doe.jpg in /public/images/
        description: 'Rocco Di Tella has a BA in Data Science from the University of Buenos Aires, where he now is a TA in statistics and is currently working on his master\'s thesis on computer vision under the supervision of Gabriel Kreiman (Harvard Medical School).'
      },
      {
        id: 7,
        name: 'Noe Hsueh',
        role: 'Data Scientist',
        photo: '/images/membersTeam/noe.jpeg', // Place john-doe.jpg in /public/images/
        description: 'Noé Hsueh, a Data Scientist from the University of Buenos Aires, excels in 3D generative AI, computer vision, NLP, and recommendation systems. Merging technical skill with creativity, Noé crafts AI solutions that blend theory with practice, advancing technology\'s frontier.'
      },
      {
        id: 8,
        name: 'Tomas Palazzo',
        role: 'Data Scientist',
        photo: '/images/membersTeam/tomas.jpg', // Place john-doe.jpg in /public/images/
        description: 'Tomas has a BA in Data Science from Buenos Aires University, specializing in Statistics and Optimization for AI. He has experience in Automatic Speech Recognition using Transformer models and a wide versatility in applying knowledge to different fields.'
      },
       ],
      expandedMember: null,
      hoveredMember: null,
      maxColumns: this.calculateColumns(),
    };
  },
  methods: {
    toggleDescriptionWithTracking(memberId) {
      this.trackSpecificEvent('Click', `Toggle Description for Member ${memberId}`);
      this.toggleDescription(memberId);
    },
    hoverMemberWithTracking(memberId) {
      this.trackSpecificEvent('Hover', `Hover on Member ${memberId}`);
      this.hoverMember(memberId);
    },
    leaveMemberWithTracking(memberId) {
      this.trackSpecificEvent('Hover', `Leave Hover on Member ${memberId}`);
      this.leaveMember(memberId);
    },
    trackSpecificEvent(action, detail) {
      mixpanel.track('About Us Interaction', {
        'action': action,
        'detail': detail
      });
    },

  mounted() {
    window.addEventListener('resize', this.updateMaxColumns);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateMaxColumns);
  },
  toggleDescription(memberId) {
      this.expandedMember = this.expandedMember === memberId ? null : memberId;
    },
    updateMaxColumns() {
      this.maxColumns = this.calculateColumns();
    },

    calculateColumns() {
      const width = window.innerWidth;
      if (width < 768) {
        this.columnsUsed = 1;
        return 1;
      } else if (width < 1024) {
        this.columnsUsed = 2;

        return 2;
      } else {
        this.columnsUsed = 3;
        return 3;
      }
    },
    hoverMember(memberId) {
      this.hoveredMember = memberId;
    },
    leaveMember() {
      this.hoveredMember = null;
    }
  },

  computed: {

    gridStyle() {
      return {
        'grid-template-columns': `repeat(${this.maxColumns}, minmax(250px, 1fr))`

      };
    }
  }
}
;
</script>


<style scoped>

.member-description {
  padding: 10px; /* Add padding to the description */
}

.read-more {
  /* Existing styles... */
  transition: background-color 0.3s;
}

.read-more:hover {
  background-color: #FFC107; /* Color change on hover */
}

.about-us {
  background-image: linear-gradient(to bottom, #031F41 50%, #ffffff 100%);

  padding: 20px;
  border: none; /* Ensures no border is visible */
  box-shadow: none; /* Removes any shadow effect */
  /* Ensure background matches the page if it's causing a visual boundary. */
  /* This assumes your page background is white; adjust as necessary. */
}

#team-introduction h2 {
  font-weight: bold; /* Make it bold for emphasis */
  font-size: 3rem; /* Retained font size for impact */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Aesthetic font */

  background: -webkit-linear-gradient(45deg, #ffffff, #ffffff); /* Soft gradient from pink to blue */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Applying the gradient to the text */
  text-shadow: 2px 2px 8px rgba(0,0,0,0.2); /* Smoother shadow for depth */
  padding: 10px 20px; /* Add some padding for better presentation */
  text-align: center;
  margin-bottom: 20px;
}

.team-description {
  text-align: center;
  margin-bottom: 40px;
  font-size: 18px;
  color: #dfe2ff;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Adjusted to space-around for even spacing */
  width: 70vw; /* Set the total width to 80% of the viewport width */
  margin: 0 auto; /* Center the team members container horizontally */
}

.team-member {
  flex-basis: calc((70vw / 3) - 40px); /* Adjusted to ensure 3 items per row considering the margin, replace 3 with the desired number of items per row */
  margin: 20px; /* Adjust margn as needed */
  text-align: center;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff
}

.member-photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.member-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.member-role {
  font-weight: bold;
  color: #1976D2;
}


.team-member:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
}





.read-more {
  background-color: #1976D2; /* Change to a more appealing color */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold; /* Make text bold */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Add subtle shadow for depth */
  transition: background-color 0.3s, transform 0.2s; /* Add transform to transition for a smoother effect */
  margin-top: 15px; /* Increase space above the button */
  margin-bottom: 30px; /* Add more space to the bottom, making it higher */
}

.read-more:hover {
  background-color: #2196F3; /* Slightly lighter shade on hover */
  transform: scale(1.05); /* Slightly enlarge on hover for interactive feel */
}

/* Adjust member description margin if necessary */
.member-description {
  margin-bottom: 10px; /* Adjust according to your layout needs */
  padding: 0 10px; /* Ensures text doesn't touch the borders */
}

/* Additional adjustments for the team member div to ensure proper spacing */
.team-member {
  padding-top: 20px; /* Increase padding at the bottom */

  padding-bottom: 30px; /* Increase padding at the bottom */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* This helps to push the button up */
}

.team-member {
  flex-basis: calc((70vw / 3) - 50px); /* Default state for larger screens */
  margin: 20px;
  text-align: center;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
}

/* Smaller screens, less than 780px */
@media (max-width: 780px) {
  .team-member {
    flex-basis: calc((70vw / 1) - 50px); /* Default state for larger screens */

  }
}

/* Medium screens, between 780px and 1200px */
@media (min-width: 781px) and (max-width: 1200px) {
  .team-member {
    flex-basis: calc((70vw / 2) - 50px); /* Default state for larger screens */

  }
}
</style>
