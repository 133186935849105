// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// plugins/vuetify.js
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

export default createVuetify({
  components,
  directives,
  // You can add global theme configurations here
  theme: {
    defaultTheme: 'light', // Choose your default theme ('light' or 'dark')
    themes: {
      light: {
        colors: {
          primary: '#1976D2', // Example of setting a custom primary color
          // Add other color customizations here
        },
      },
      // Define a dark theme variant if needed
    },
  },
  // Any other Vuetify global options go here
})
