<template>
  <div class="home">
    <!-- Heading image with text before Services -->
    <section class="heading-with-logo">
      <!-- Placeholder for logo and welcome message -->
    </section>

    <section id="neuralNetowrk" class="section">
      <ParticlesLibrary />
    </section>
   
    <!-- Include Services Section -->
    <section id="services">
      <ViewServices />
    </section>

    <div style="height: 10vh;"></div>

    <!-- Include About Us Section -->
    <section id="about-us">
      <AboutUs />
    </section>
    <section id="logo-carousel">
      <LogoCarousel /> <!--  -->
    </section>
    <VideoBubble/>
    <Chatbox/>

  </div>
</template>

<script>
import ViewServices from './ViewServices.vue';
import AboutUs from './AboutUs.vue';
import VideoBubble from '../components/VideoBubble.vue';
import ParticlesLibrary from '../components/ParticlesLibrary.vue';
import Chatbox from '../components/ChatbotBox.vue'; 
import LogoCarousel from '../components/LogoCarousel.vue'; 
export default {
  name: 'ViewHome',
  components: {
    ViewServices,
    AboutUs,
    ParticlesLibrary,
    VideoBubble,
    Chatbox,
    LogoCarousel,
  },
  watch: {
    // Watch for changes in the route
    '$route'(to) {
      if (to.path === '/services') {
        this.scrollToSection('services');
      } else if (to.path === '/about-us') {
        this.scrollToSection('about-us');
      }
    },
  },
  methods: {
    scrollToSection(sectionId) {
      this.$nextTick(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
  },
};
</script>


<style scoped>


.about-us {
  max-width: 100%; /* Ensures the container can expand fully */
  width: 100%;
  margin: 0 auto; /* Centers the container without restricting its width */
  padding: 20px; /* Adjust padding as needed, but be mindful it can affect inner width */
  box-sizing: border-box; /* Ensures padding does not add to the total width */
}
.section {
  position: relative;
}

.section::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1vh; /* Height of the gradient border */
  background: linear-gradient(to bottom, #031F41, #ffffff); /* Example gradient */
}

</style>
