<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="left-content">
        <div class="contact">
          <h3>Contact Us</h3>
          <p @click="trackSpecificEvent('Email Click', 'info@deepvance.com')">Email: info@deepvance.com</p>
          <p @click="trackSpecificEvent('Phone Click', '+54 911 3593 2755')">Phone: +54 911 3593 2755</p>
        </div>
      </div>
      <div class="middle-content">
        <div class="social">
          <h3>Follow Us</h3>
          <div class="social-icons">
            <a href="https://twitter.com/deepvanceai" target="_blank" aria-label="Twitter" @click.stop="trackSpecificEvent('Twitter Follow', 'deepvanceai')">
              <div class="icon-wrapper">
                <img src="/icons/twitter-icon.png" alt="Twitter">
              </div>
            </a>
            <a href="https://www.linkedin.com/company/102219955/" target="_blank" aria-label="LinkedIn" @click.stop="trackSpecificEvent('linkedin Follow', 'deepvanceai')">
              <div class="icon-wrapper">
                <img src="/icons/linkedin.png" alt="LinkedIn">
              </div>
            </a>
            <a href="https://instagram.com/deepvanceai" target="_blank" aria-label="Instagram" @click.stop="trackSpecificEvent('Instagram Follow', 'deepvanceai')">
              <div class="icon-wrapper">
                <img src="/icons/insta.png" alt="Instagram">
              </div>
            </a>
            <a href="https://github.com/deepvanceai" target="_blank" aria-label="Github" @click.stop="trackSpecificEvent('Github Follow', 'deepvanceai')">
              <div class="icon-wrapper">
                <img src="/icons/git.png" alt="Github">
              </div>
            </a>
          </div>
        </div>
      </div>
      <!-- The right-content can include additional content or be removed if not used -->
      <div class="right-content">
        <!-- Optionally, additional content here -->
      </div>
    </div>
  </footer>
</template>

<script>
import mixpanel from 'mixpanel-browser';

export default {
  name: 'AppFooter',
  methods: {
    trackSpecificEvent(action, detail) {
      // Tracks clicks on specified elements with detailed information
      mixpanel.track('Footer Specific Interaction', {
        'action': action,
        'detail': detail
      });
    }
  },
};
</script>
<style scoped>
.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 5%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-around; /* Updated for even spacing */
  width: 100%;
}

.left-content, .middle-content, .right-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px; /* Added padding for spacing */
}

.contact, .social {
  margin: 20px 0; /* Adjusted for uniform spacing */
}

h3 {
  margin-bottom: 15px;
}

.social-icons {
  display: flex;
  justify-content: center; /* Center align the icons */
}

.social-icons a {
  display: inline-flex;
  margin-right: 10px;
  text-decoration: none;
}

.icon-wrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.icon-wrapper:hover {
  background-color: #046dde;
}

.social-icons img {
  width: 80%;
  height: auto;
  transition: transform 0.3s ease;
}

.icon-wrapper:hover img {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .left-content, .middle-content, .right-content {
    width: 100%;
    align-items: center;
    padding: 10px 0; /* Adjusted padding for mobile */
  }

  .contact, .social {
    margin: 10px 0;
  }

  .social-icons {
    justify-content: center;
    margin-top: 10px;
  }
}

/* Navbar and Footer Enhancements */
.navbar, .footer {
  background-color: #031F41;
}

.navbar a:hover, .footer a:hover {
  color: #FFC107;
}

.logo, h3 {
  color: #ffffff;
}
</style>
