<template>
  <div v-if="isVisible" class="video-wrapper">
    <div id="video-bubble"
         :class="{'bubble': !isExpanded, 'expanded': isExpanded}"
         @click="togglePlayback"
         @mouseover="expand"
         @mouseleave="contract"
         ref="videoBubble">
         <video ref="videoPlayer"
                @ended="onVideoEnd"
                :muted="isInitialVideoMuted"
                :class="{ 'video-cover': !isExpanded, 'video-contain': isExpanded }">
            <source :src="currentVideoSrc" type="video/mp4">
            Your browser does not support the video tag.
          </video>

      <div v-if="showOptions" class="options">
        <button v-for="option in currentOptions" 
                @click.stop="changeVideo(option.targetVideoId)" 
                :key="option.label"
                :style="{ fontSize: dynamicStyles.buttonSize }">
          {{ option.label }}
        </button>
        <button @click.stop="hide">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dynamicStyles: {
      videoWidth: '600px', // Default width, you'll dynamically change this
      buttonSize: '80%', // Default button size, you'll dynamically change this
      },
      showOptions: false,
      isExpanded: false,
      isVisible: true,
      isPlayingWithSound: false, // Add this to track if the video is playing with volume

      videos: [
        { id: 'welcomeVideo', src: '/videos/welomesubs.webm', options: [
          { label: 'How to book a consultation', targetVideoId: 'bookingeazy' },
          { label: 'I can\'t find what I am looking for ', targetVideoId: 'didnfind' },
        ]},
        { id: 'bookingeazy', src: '/videos/bookineasy3.webm', options: [
          { label: 'Back to Welcome', targetVideoId: 'welcomeVideo' },
          { label: 'I can\'t find what I am looking for', targetVideoId: 'didnfind' },
        ]},
        { id: 'didnfind', src: '/videos/didntfindsub2.webm', options: [
          { label: 'Back to Welcome', targetVideoId: 'welcomeVideo' },
          { label: 'How to book a consultation', targetVideoId: 'bookingeazy' },
        ]}
        // Define other videos and their options here
      ],
      currentVideoId: 'welcomeVideo',
      isInitialVideo: true,
      isInitialVideoMuted: true,

    };
  },
  computed: {
    currentVideoSrc() {
      const video = this.videos.find(v => v.id === this.currentVideoId);
      return video ? video.src : '';
    },
    currentOptions() {
      const video = this.videos.find(v => v.id === this.currentVideoId);
      return video ? video.options : [];
    },
  },
  mounted() {
    this.makeDraggable();
  this.startInitialVideo();
  this.adjustVideoSize(); // Existing call
  this.checkVisibility(); // Check initial visibility based on window width
  window.addEventListener('resize', this.adjustVideoSize); // Existing listener
  window.addEventListener('resize', this.checkVisibility);
},

  beforeUnmount() {
    window.removeEventListener('resize', this.adjustVideoSize); // Existing cleanup
    window.removeEventListener('resize', this.checkVisibility);   
  },
  watch: {
    // Watch the video's play state and mute state
    'isExpanded'(newVal) {
      // When expanding, decide if overflow should be hidden based on isPlayingWithSound
      this.updateOverflowStyle(newVal && !this.isPlayingWithSound);
    }
  },
  methods: {
    updateOverflowStyle() {
      const bubbleElement = this.$refs.videoBubble;
      if (bubbleElement) {
        const shouldHideOverflow = !this.isExpanded || !this.isPlayingWithSound;
        bubbleElement.classList.toggle('hide-overflow', shouldHideOverflow);
      }
    },

    checkVisibility() {
      this.isVisible = (window.innerWidth >= 800 &&  window.innerHeight >= 500);
    },
    adjustVideoSize() {
      if (!this.$el || !this.$el.style) {
        console.error('Element or style not available');
        return;
      }

      // Determine the size of the video based on whether it's expanded.
      const proportion = this.isExpanded ? 0.33 : 0.1;
      const size = window.innerWidth * proportion;

      // Calculate the button font size based on the video size or window size
      const baseFontSize = 12; // Base font size in pixels
      const scalingFactor = 0.025; // Adjust this factor based on your needs
      const buttonFontSize = Math.max(baseFontSize, size * scalingFactor) + 'px'; // Ensure a minimum font size

      const newSize = `${size}px`;
      // Assuming --video-width and --video-height are used in your component's styles
      this.$el.style.setProperty('--video-width', newSize);
      this.$el.style.setProperty('--video-height', newSize);
      
      // Adjust button font size based on newSize
      this.$el.style.setProperty('--button-font-size', buttonFontSize);
    },
    // Existing methods with slight adjustments to accommodate the new data structure
    changeVideo(targetVideoId) {
    this.currentVideoId = targetVideoId; // Update the current video ID to change the video source via the computed property
    this.$nextTick(() => {
      const videoElement = this.$refs.videoPlayer;
      if (videoElement) {
        videoElement.load(); // Explicitly load the new video source
        this.playVideo(); // Play the new video
      }
    });
  },
    startInitialVideo() {
      const video = this.$refs.videoPlayer;
      video.muted = true;
      video.play().catch(error => console.error('Playback error:', error));
    },
    togglePlayback() {

      const video = this.$refs.videoPlayer;
      if (this.isInitialVideo) {
        video.currentTime = 0;
        video.muted = false;
        this.isInitialVideo = false;
        this.playVideo();
      } else {
        video.paused ? this.playVideo() : this.pauseVideo();
      }
      this.adjustVideoSize()
      this.isPlayingWithSound = !video.paused && !video.muted;
      this.updateOverflowStyle(!this.isPlayingWithSound);
    },
    playVideo() {
    const videoElement = this.$refs.videoPlayer;
    videoElement.muted = false;
    if (videoElement) {
      videoElement.play().then(() => {
        this.showOptions = true;
        this.isExpanded = true;
        this.isPlayingWithSound = true; // Update play state with sound

        this.updateOverflowStyle(false); // Show overflow when playing with sound

      }).catch(error => console.error('Playback error:', error));
    }
  },
    pauseVideo() {
      this.$refs.videoPlayer.pause();
      this.showOptions = false;
      this.isExpanded = false;
      this.isPlayingWithSound = false; // Update play state
      this.updateOverflowStyle(true); // Hide overflow when paused
    },
    onVideoEnd() {
      const video = this.$refs.videoPlayer;

      // Check if video exists before trying to access its properties
      if (video && this.isInitialVideo && video.muted) {
        this.startInitialVideo();
      } else {
        this.showOptions = true;
      }
      this.adjustVideoSize()
    },
    hide() {
      this.isVisible = false;
    },
    makeDraggable() {
      /*
      const bubble = this.$refs.videoBubble;
        bubble.addEventListener('mousedown', (e) => {
          let offsetX = e.clientX - bubble.getBoundingClientRect().left;
          let offsetY = e.clientY - bubble.getBoundingClientRect().top;

          const onMouseMove = (e) => {
            bubble.style.right = 'auto';
            bubble.style.bottom = 'auto';
            bubble.style.left = `${e.clientX - offsetX}px`;
            bubble.style.top = `${e.clientY - offsetY}px`;
          };

          document.addEventListener('mousemove', onMouseMove);
          document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', onMouseMove);
          }, { once: true });
        });
        */

    },
  },
};
</script>



  <style scoped>
  .video-wrapper {
    position: relative; /* Relative position to allow absolute positioning inside */
    width: fit-content; /* Only be as wide as its contents */
    height: fit-content; /* Only be as tall as its contents */
    z-index: 20; /* Ensure it's on top of other content */
  }

  #video-bubble {
    position: fixed;
    width: var(--video-width, 150px); /* Use CSS variable for width */
    height: var(--video-height, 150px); /* Use CSS variable for height */
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    overflow: hidden; /* Hide the video overflow */
    transition: all 0.5s ease-in-out;
    
  }
  #video-bubble.hide-overflow {
  overflow: hidden;
}

  #video-bubble video {
    width: 100%;
    height: 100%;

  }
      .video-cover {
      object-fit: cover;
    }

    .video-contain {
      object-fit: contain;
    }


  #video-bubble.expanded {
    width: 50%; /* Expanded width */
    height: 50%; /* Expanded height */
    border-radius: 20px; /* No border radius when expanded */
    object-fit: contain;

  }


  #video-bubble.bubble {
    border-radius: 50%; /* Circular border radius when not expanded */
  }

  #video-bubble.pre-expanded {
    width: 180px; /* Slightly larger when pre-expanded */
    height: 180px;
    transition: all 0.3s ease-in-out;
  }

  .close-btn {
    position: absolute;
    top: 0; /* Position at the top right of the video-wrapper */
    right: 0;
    background-color: #fff;
    border: 2px solid black;
    border-radius: 50%;
    cursor: pointer;
    font-size: 40px;
    padding: 5px;
    transform: translate(0%, 0%); /* Adjust to center the button's position correctly */
    z-index: 21; /* Above the video bubble */
  }
  .options {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  /* This now relies on Vue to set the correct width dynamically, so the static calc() is removed */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 12px 4px rgba(0, 0, 0, 0.2);
  gap: 20px;
  z-index: 22; /* Ensure it's above the video bubble */
}


.options button {
  background-color: #1976D2; /* Change to a more appealing color */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 3px 8px;
  font-size: var(--button-font-size, 6px);
  cursor: pointer;
  font-weight: bold; /* Make text bold */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Add subtle shadow for depth */
  transition: background-color 0.3s, transform 0.2s; /* Add transform to transition for a smoother effect */
  margin-top: 0; /* Adjust if needed */
  margin-bottom: 20; /* Adjust if needed */
  z-index: 23; /* Above the options background */
}

.options button:hover {
    background-color: #2196F3; /* Slightly lighter shade on hover */
  transform: scale(1.05);
}

  </style>

