import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      posts: [
        {
            "id": 1,
            "title": "Optimizing Inventory with Scientific Approach",
            "summary": "We crafted a dynamic, data-driven stock optimization model for import businesses, minimizing costs and balancing stock efficiently.",
            "content": `<p>In the competitive landscape of import businesses, inventory management is not just an operational task; it's a strategic endeavor that significantly impacts financial health. Our latest engagement with a client showcases a data-driven and scientific methodology that revolutionized their approach to stock optimization. </p>
            <br>
            <h3>The Client's Dilemma</h3>
            <p>Our client was facing a classic dilemma: maintaining sufficient stock to meet demand without incurring the heavy costs of overstocking. They needed a solution that could dynamically adjust to their complex inventory needs, factor in various costs, and optimize their ordering process to maintain an efficient supply chain.</p>
            <br>
            <h3>Our Scientific Approach</h3>
            <p>To address this challenge, we employed a linear optimization model grounded in mathematical theory. Our model was developed on the foundation of carefully defined parameters and constraints, tailored to mirror the operational realities of the client's business.</p>
            <br>
            <h3>Defining Parameters and Constraints:</h3>
            <ul>
              <li> Initial Stock (STI): The quantity of each product at the onset, setting the stage for our calculations.</li>
              <li> Estimated Demand (DE): A forecast based on historical data to predict future sales.</li>
              <li> Product Cost (CO): The acquisition cost per product, critical for financial assessment.</li>
              <li> Supplier Ordering Restrictions (EP & MP): Minimum order quantities and supplier-specific constraints, ensuring compliance and efficient supplier management.</li>
              <li> Customs and Logistics Fixed Costs (CDES): Non-negotiable expenses that significantly affect ordering strategies.</li>
            </ul>
            <p>These parameters were complemented by a series of constraints that acknowledged real-world complexities such as delivery lead times (DELY), and the cost implications of holding deadstock, penalized through the Deadstock Coefficient (COEFINT).</p>
            <br>
            <h3>Mathematical Formulation of the Problem:</h3>
            <p>We constructed an objective function to minimize the total cost, which includes purchasing costs, shipping, handling, and penalties for excess inventory. Our model accounted for the intricate relationships between different types of costs, demand fluctuations, and stock levels over time.</p>
            <br>
            <h3>The Optimization Algorithm:</h3>
            <p>The deliverable was a dynamic optimization algorithm that integrates all these elements. This program conducts real-time analyses, adjusting recommendations for order quantities and timings based on the latest data inputs regarding stock levels, market demand, and cost changes. each decision, providing a comprehensive view of the supply chain's fiscal health. </p>
            <br>
            <h3>The Outcome</h3>
            <p>The application of our model delivered a transformative outcome for our client. They witnessed a significant reduction in overstock costs, a better alignment of stock levels with demand, and an overall more resilient and responsive supply chain.</p>
            <br>
            <h3>Extending Our Expertise</h3>
            <p>This case exemplifies the power of applied mathematics in solving complex business problems. Our expertise in developing and implementing such scientifically rigorous models can be adapted to a wide range of industries and company sizes. We invite businesses facing similar inventory challenges to reach out for a consultation to explore how our analytical models can drive efficiency and cost savings.</p>`,
            youtubeVideoId: null,
            githubRepoUrl: null,
            huggingFaceUrl: null
          },
          {
            id: 2,
            title: "Adapting AI: Customizing the Mistral Model for Diverse Industry Needs",
            summary: "We explore the process of finetuning the Mistral model to bridge the gap between academic research and practical code implementation.",
            content: `<p>In our initiative, we faced the unique challenge of adapting the Mistral model for a specialized task: generating docstrings from code-related queries. This was part of a larger effort to enhance the integration of code and academic research, making it easier to connect specific code sections with relevant academic papers. Our aim was to refine the model to recognize and convert standard queries into docstring-like responses, facilitating better alignment in vector space and improving the efficiency of code-paper linkage.</p>
            <br>
            <p>The challenge stemmed from the model's initial training, which focused on docstring comprehension, necessitating a shift towards handling more natural, query-based interactions. Through finetuning Mistral, we sought to bridge this gap, creating a more intuitive and effective interface between coding and academic research, thereby enhancing our system's retrieval capabilities.</p>
            <br>
            <h3>Adaptive Solution for Broad Applications</h3>
            <p>To tackle this, we embarked on a finetuning journey with Mistral, starting with the creation of a dataset containing query-docstring pairs. This involved selecting high-quality docstrings from the UnixCoder dataset, refined using a ranking system powered by the ChatGPT 4 API. We generated a variety of queries to accompany these docstrings, forming a comprehensive dataset that reflects the dynamic nature of code inquiries.
            </p>
            <br>
            <p>This preparation paved the way for the finetuning of Mistral, utilizing advanced computing to tailor the model efficiently. The refined model, now adept at translating queries into docstrings, marked a significant stride in our project, achieving remarkable performance improvements and reinforcing our objective of seamlessly integrating code with academic literature.
            </p>
            <br>
            <h3>Extending Our Model's Reach</h3>
            <p>The success of our finetuning process not only met our project's specific needs but also highlighted Mistral's potential for broader applications. The adaptability of our approach signifies that this model can be customized to meet diverse AI requirements. Whether a company is seeking to enhance code-documentation linkage, improve natural language processing capabilities, or address other unique challenges, our experience demonstrates that with targeted finetuning, AI models like Mistral can be effectively adapted to serve varied and specific industry needs.
            </p>
            <br>
            <p>We have made the model publicly available, inviting the community to review, utilize, and further enhance the model, thereby fostering open collaboration and innovation in the field of AI. 
            </p>`,
            youtubeVideoId: null, 
            githubRepoUrl: null,
            huggingFaceUrl: "https://huggingface.co/axel-datos/mistral_finetuned"
          },
          {
            "id": 3,
            "title": "Elevating ChatGPT: Tailoring AI for Your Business Needs",
            "summary": "With our approach companies can leverage GPT's conversational AI capabilities for more precise, task-specific applications, enhancing operational efficiency and customer experience.",
            "content": `<h3></h3>
            <p>In the rapidly evolving world of AI, Large Language Models (LLMs) like GPT have been game-changers, especially in how we interact with technology through conversation. Yet, when it comes to specific, real-time tasks, such as pulling up a business's operational hours or predicting tomorrow's weather, there's a gap that needs bridging. That's where our project steps in, blending the conversational prowess of LLMs with the precision of structured code responses.</p>
            <br>
            <h3>The Challenge</h3>
            <br>
            <p>Businesses often find themselves at a crossroads: they have access to powerful AI tools like ChatGPT but struggle to tailor them for niche, specific tasks. The complexity of integrating external functions into these models can be daunting, with traditional methods requiring a deep dive into JSON schema formatting, which is both time-consuming and technically challenging.
            </p>
            <br>
            <h3>Our Solution</h3>
            <br>
            <p>We've developed a streamlined approach that simplifies this integration, allowing businesses to define functions in an easy-to-understand format. This means you can tell the AI exactly what you need, in plain language, and it will execute the function accordingly. Our enhanced model autonomously recognizes, calls, and fetches responses from these user-defined functions, adjusting parameters dynamically to fit the task at hand.</p>
            <br>
            <h3>How It Works</h3>
            <br>
            <p>Imagine needing to know the peak hours of your stores across different locations. Instead of sifting through data manually or setting up complex query systems, our model can be programmed to understand and respond to these specific inquiries through simple conversational triggers. This not only saves time but also ensures accuracy and relevancy in the responses provided.</p>
            <br>
            <h3>Benefits for Your Business</h3>
            <br>
            <p>Adopting this model means more than just having an AI assistant; it's about owning a bespoke tool that adapts to your business's unique needs. Whether you're in retail, hospitality, or any sector in between, the model can be fine-tuned to offer precise, actionable information, enhancing customer experience and streamlining operations.</p>
            <br>
            <h3>Conclusion</h3>
            <p>Our journey into enhancing LLMs for practical, real-world applications is just beginning. By marrying the conversational flexibility of ChatGPT with the structured, logical capabilities of code execution, we've opened a new realm of possibilities for businesses. This is more than an upgrade; it's a transformation, enabling companies to harness the full potential of AI tailored specifically for their needs.</p>`,
            "youtubeVideoId": "SS_GVlGG2AA?si=vwTLLZMDVQnc0NCS",
            "githubRepoUrl": "https://github.com/AxelFridman/agent_automatic_tool_generation",
             huggingFaceUrl: null
          },{
            id: 4,
            title: "Customized Text-to-Image Solutions",
            summary: "We've embarked on an innovative project harnessing AI's text-to-image capabilities to create custom, visually engaging content.",
            content: `
            <p>In the digital era, the integration of artificial intelligence with creative innovation is transforming content creation. At Deep Vance AI, we've harnessed text-to-image AI technology to pioneer personalized content creation, demonstrating the potential of AI to place individuals in iconic locations worldwide. </p>
            <br>
            <h3>Project Genesis</h3>
            <br>
            <p>Our project started with an ambitious objective: to generate a series of images situating  a single individual against the backdrop of iconic global landmarks. starting with the compilation of an extensive dataset. Over 500 photos were meticulously selected, utilizing Google Photos to curate a diverse and comprehensive collection that captures various facets of the individual's persona. </p>
            [[Image1]]
            <br>
            <h3>Technical Odyssey</h3>
            <br>
              <p>To enrich the dataset, we employed the "nlpconnect/vit-gpt2-image-captioning" model, generating descriptive captions for each photo. This process was instrumental in creating a textual context for each image, laying the groundwork for the subsequent image generation phase. The captions provided a detailed narrative for each photograph. With the initial captions, we meticulously modified them to include persona's name, ensuring each description was personalized and aligned with the project's vision. </p>
              <br>
              <p>The endeavor centered on optimizing the "runwayml/stable-diffusion-v1-5" model. We encountered and overcame initial challenges by integrating advanced face embedding technology, enhancing the model’s ability to produce realistic and personalized images. This crucial development significantly improved the quality and authenticity of the output.</p>
              <br>
              <h3>Breakthrough and Results</h3>
              <br>
              <p>The project's outcome was remarkable, yielding high-quality images that were both authentic and personal. This success illustrates the capacity of AI to break new ground, offering tailor-made solutions that resonate on a personal level.</p>
              [[Image3]]
              <br>
              [[Image2]]
              <br>
              <h3>Implications for Businesses</h3>
              <br>
              <p>This project demonstrates the potential of text-to-image AI solutions for businesses seeking innovative content creation strategies. The technology paves the way for marketing campaigns, personalized customer experiences, and distinctive offerings, heralding a new era of personalized engagement.
              </p>
              <br>
              <p>We are excited to explore and expand the horizons of AI-driven personalization, promising a visually stunning and transformative future for businesses and consumers alike.
              </p>
            `,
            images: {
              Image1: {
                src: '/images/blogs/valenreal.jpeg',
                alt: null,
                caption: "Figure 1: Individual's real photo."
              },
              Image2: {
                src: '/images/blogs/valenroma.jpeg',
                alt: null,
                caption: 'Figure 2: Generated image, Individual in Rome.'
              },
              Image3: {
                src: '/images/blogs/valenparis.jpeg',
                alt: null,
                caption: 'Figure 3: Generated image, Individual in Paris.'
              },
            },
            huggingFaceUrl: null,
            youtubeVideoId: null, 
            githubRepoUrl: null
          }
      ]
    };
  },
  getters: {
    getPostById: (state) => (id) => {
      return state.posts.find(post => post.id === id);
    }
  },
});
