import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './stores/blogsIndex.js';

// Uncomment the next line if you're using Vuex store
// import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';

// import style

loadFonts()

// Create a Vue application instance
const app = createApp(App)
app.use(VueAwesomeSwiper);

app.use(router)

app.use(vuetify)
app.use(store) 

app.mount('#app')
