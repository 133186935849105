<template>
  <div class="blog-container">
    <div class="login-container" v-if="!userAuthenticated">
      <input v-model="username" placeholder="Username" class="login-input"/>
      <input v-model="password" type="password" placeholder="Password" class="login-input"/>
      <button @click="loginUser" class="login-button">Login</button>
    </div>
    <div v-if="userAuthenticated">
      <h2 class="title">Search Nomenclature</h2>
      <p class="description">Enter your query below to search for specific nomenclatures.</p>
      <div class="blog-posts">
        <input v-model="query" class="query-input" placeholder="Enter your query here" @keyup.enter="getNomenclatura"/>
        <button @click="getNomenclatura" class="search-button">Search</button>
        <div v-if="loading" class="loading">Loading...</div>
        <div v-for="(item, index) in responseItems" :key="index" class="blog-post response-box">
          <p><b class="post-title">Nomenclatura:</b> {{ item.nomenclatura }}</p>
          <p><b class="post-excerpt">Razón:</b> {{ item.razon }}</p>
          <p><b class="post-excerpt">Cita:</b> {{ item.cita }}</p>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      userAuthenticated: false,
      userCredits: 0, // Added to manage user's credits
      query: '',
      responseItems: [],
      loading: false,
    };
  },
  methods: {
    async loginUser() {
      try {
        const loginResponse = await fetch('https://backend-nuestra-web-3541c3056b46.herokuapp.com/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username: this.username, password: this.password }),
        });
        const loginData = await loginResponse.json();
        if (loginData.authenticated) {
          this.userAuthenticated = true;
          this.userCredits = loginData.credits; // Update credits
          alert(`Login successful. Welcome, ${this.username}! Credits remaining: ${loginData.credits}`);
        } else {
          alert("Authentication failed or no credits left.");
        }
      } catch (error) {
        console.error("Login error:", error);
      }
    },
    async getNomenclatura() {
      if (!this.userAuthenticated) {
        alert("Please log in first.");
        return;
      }
      this.loading = true;
      this.responseItems = []; // Clear previous results
      try {
        const response = await fetch('https://backend-nuestra-web-3541c3056b46.herokuapp.com/nomenclatura', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ query: this.query, username:this.username }),
        });
        const data = await response.json();
        console.log(data)
        this.formatResponse(data);

        // Deduct a credit after a successful search
        const response2 = await fetch('https://backend-nuestra-web-3541c3056b46.herokuapp.com/updatecredit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username: this.username, decrement: 1 }),
        });
        this.userCredits--; // Decrement locally for immediate UI update
        if (this.userCredits <= 0) {
          this.userAuthenticated = false; // Optional: Require re-login or credits recharge
          alert("You've used all your credits. Please recharge to continue.");
        }
        const data2 = await response2.json();
        console.log(data2)
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {

        this.loading = false;
      }
    },

    formatResponse(data) {
      if (data.listaOrdenada && Array.isArray(data.listaOrdenada)) {
        this.responseItems = data.listaOrdenada.map(item => ({
          cita: item.cita,
          nomenclatura: item.nomenclatura,
          razon: item.razon,
        }));
      }
    }
  }
}

</script>
  <style scoped>
  .blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    width: 100vw;
  }
  .other-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    width: 100vw;
  }
  .blog-posts{
    width: 80vw;
    margin-top: 60%;
    padding: 60%;
  }
  .title, .description {
    color: #1976D2; /* Aesthetic Blue */
    margin-top: 10%;
    padding: 10%;
  }

  .title {
    font-size: 28px;
    margin-bottom: 10px;
  }

  .description {
    margin-bottom: 20px;
    font-size: 18px;
    color: #333;

  }

  .query-input, .search-button {
    width: 80%;
    max-width: 600px;
    padding: 12px 20px;
    margin: 10px 0;
    border-radius: 20px;
    border: 1px solid #1976D2; /* Aesthetic Blue Border */
    box-shadow: inset 0 1px 3px rgba(0,0,0,0.1);
    outline: none;

  }

  .query-input {
  width: 80%;
  max-width: 600px;
  padding: 12px 20px;
  margin: 10px 50px; /* Existing margin */
  border-radius: 20px;
  border: 1px solid #1976D2; /* Aesthetic Blue Border */
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.1);
  outline: none;

}

.search-button {
  cursor: pointer;
  background-color: #1976D2; /* Aesthetic Blue */
  color: white;
  border: none;
  transition: background-color 0.3s, transform 0.2s;
  margin-top: 50px; /* Added margin to separate from the input */

}

  .search-button:hover {
    background-color: #145CA8; /* Darker Shade for Hover */
    transform: scale(1.05);
  }

  .loading {
    font-size: 18px;
    color: #1976D2;
    margin-top: 20px;
  }

  .response-box {
    border: none;
    margin-bottom: 40px;
    padding: 20px;
    background-color: #E3F2FD; /* Light blue background */
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    transition: transform 0.2s, box-shadow 0.2s;
  }

  .response-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0,0,0,0.15);
  }

  .post-title, .post-excerpt {
    color: #1976D2;
  }

  .post-excerpt {
    font-size: 18px;
    line-height: 1.6;
    margin-top: 10px;
  }



  .login-container {
  position: absolute; /* Or fixed, depending on your navbar setup */
  margin-bottom: 20vw;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Creates space between elements */
}

.login-input, .login-button {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-button {
  background-color: #1976D2;
  color: white;
  cursor: pointer;
}

.login-button:hover {
  background-color: #145CA8;
}

.query-input:disabled, .search-button:disabled {
  background-color: #f0f0f0;
  color: #ccc;
  cursor: not-allowed;
}

/* Add this to ensure that the disabled button text color contrasts enough */
.search-button:disabled {
  background-color: #e0e0e0;
}
@media (max-width: 768px) {
  .query-input, .search-button {
    width: 90%; /* Adjust width for smaller screens */
  }

  .blog-container, .other-container {
    padding: 10px;
    width: 95vw; /* Adjust container width for better fit on smaller screens */
  }

  .title {
    font-size: 24px; /* Adjust title size for smaller screens */
  }

  .description {
    font-size: 16px; /* Adjust description size for readability on smaller screens */
  }

  /* Adjust login container for mobile */
  .login-container {
    top: 60px; /* Adjust position to ensure visibility */
    right: 10px;
    padding: 8px;
  }
}
.blog-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  min-height: 100vh; /* Full height of viewport */
  padding: 60px 20px 20px; /* Adjusted padding to ensure space for navbar and footer */
  box-sizing: border-box; /* Ensures padding is included in height calculation */
  margin-top: -60px; /* Adjust according to navbar height to ensure content starts below navbar */
}

/* Adjust login and blog post container for better responsiveness and spacing */
.login-container, .blog-posts {
  width: 100%;
  max-width: 600px; /* Maximum width */
  margin: 20px auto; /* Centering and spacing */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

/* General style adjustments for inputs and buttons for consistency */
.login-input, .login-button, .query-input, .search-button {
  width: 100%;
  max-width: 580px; /* Slightly less than container to fit nicely */
  padding: 10px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-button, .search-button {
  background-color: #1976D2;
  color: white;
  cursor: pointer;
  border: none; /* Remove border for buttons */
}

.login-button:hover, .search-button:hover {
  background-color: #145CA8;
}

/* Style for disabled input and button */
.query-input:disabled, .search-button:disabled {
  background-color: #f0f0f0;
  color: #ccc;
  cursor: not-allowed;
}

.search-button:disabled {
  background-color: #e0e0e0;
}

@media (max-width: 768px) {
  /* Adjust widths for smaller screens */
  .login-container, .blog-posts, .login-input, .login-button, .query-input, .search-button {
    width: 90%;
  }

  .blog-container {
    padding: 60px 10px 10px; /* Adjust padding for smaller screens */
    margin-top: -60px; /* Adjust according to navbar height */
  }
}

/* Add custom styles for your footer here to ensure it stays at the bottom */
/* Input and Button Styling */
.login-input, .query-input, .login-button, .search-button {
  font-size: 1rem; /* Accessible font size */
  line-height: 1.5; /* Improved readability */
}

.login-input:focus, .query-input:focus {
  border-color: #145CA8;
  outline: none; /* Removing default outline for custom styling */
  box-shadow: 0 0 0 2px rgba(20, 92, 168, 0.25); /* Custom focus glow */
}

/* Disabled State Styling for Inputs and Buttons */
.query-input:disabled, .search-button:disabled {
  opacity: 0.5; /* Clearly indicate disabled state */
}
@media (min-width: 1024px) {
  .login-container, .blog-posts {
    max-width: 800px; /* Larger max-width for larger screens */
  }
}

@media (max-width: 480px) {
  /* Adjust font sizes for smaller screens for better legibility */
  .title {
    font-size: 20px;
  }

  .description, .post-excerpt {
    font-size: 14px;
  }

  /* Adjust padding and margin for a tighter layout on small screens */
  .login-container, .blog-posts {
    padding: 15px;
    margin: 10px auto;
  }
}
</style>
