<template>
    <div class="logo-section">
      <div id="services-main-title">Companies That Trust Us</div>
      <div class="logo-carousel-container">
        <div class="logo-carousel">
          <div class="logo-slide" v-for="(logo, index) in logos" :key="index">
            <a :href="logo.link" target="_blank">
              <img :src="logo.src" :alt="logo.name" class="logo-image" />
            </a>
          </div>
        </div>
      </div>
      <div class="transition-line"></div> <!-- Transitional design element -->
    </div>
  </template>

  <script>
export default {
  data() {
    return {
      logos: [
        { src: "icons/logo_exactas_uba.jpeg", link: "https://exactas.uba.ar/", name: "UBA Exactas" },
        { src: "icons/aries.jpeg", link: "https://www.aries-comercial.com/", name: "Aries Comercial S.A" },
        // { src: "icons/q21.png", link: "https://www.q21sportfitness.com/", name: "Q21 Sport Fitness" },
      ],
      currentSlide: 0,
      slideWidth: 0,
    };
  },
  computed: {
    targetLogoIndex() {
      // Dynamically determine the center logo based on the total count
      return Math.floor(this.logos.length / 2);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initCarousel();
      this.centerTargetLogo();
    });
  },
  methods: {
    initCarousel() {
      this.slideWidth = this.$el.querySelector('.logo-slide').clientWidth;
    },
    centerTargetLogo() {
        const carouselContainer = this.$el.querySelector('.logo-carousel-container');
        const centerOfCarouselContainer = carouselContainer.offsetWidth / 2;
        const centerOfLogoSlide = (this.slideWidth + 10) * (this.targetLogoIndex + 0.5);
        const offset = centerOfLogoSlide - centerOfCarouselContainer;

        carouselContainer.scrollLeft = offset;
        },
  },
};
</script>
<style>
.logo-section {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children */
  padding: 3vw 1vw; /* Adjusted padding for better appearance */
}

#services-main-title {
  font-weight: bold;
  font-size: 3rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Aesthetic font */

  background: -webkit-linear-gradient(45deg, #3787e9, #031F41);
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 8px rgba(0,0,0,0.2);
  padding: 2vw 4vw;
  border-radius: 5px;
  text-align: center;
  z-index: 1; /* Ensure title is above logos */
}

.logo-carousel-container {
  overflow-x: auto; /* Allow horizontal scrolling */
  display: flex;
  justify-content: center; /* Center the logos */
  width: 100%;
  padding: 2vw 0; /* Add some vertical padding */
}

.logo-carousel {
  display: flex;
  justify-content: center; /* Center logos */
  flex-wrap: nowrap; /* Prevent logos from wrapping */
}
.logo-slide {
  margin: 0 5px;
  padding: 1vw;
  box-sizing: border-box;
  width: 10vw; /* Set fixed width */
  height: 10vw; /* Set fixed height */
  min-width: 80px;
  max-width: 200px;
  min-height: 80px;
  max-height: 200px;
  display: flex;
  justify-content: center; /* Center content */
  align-items: center; /* Align content vertically */
  transition: transform 0.3s ease; /* Smooth transition for scaling */
}

.logo-slide:hover {
  transform: scale(1.08); /* Slightly increase size on hover */
}

.logo-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  object-fit: contain; /* Ensure logos fit without distortion */
}

</style>
