<template>
  <div class="meeting-scheduler">
    <div id="calendly" :style="{ height: calendlyHeight, width: calendlyWidth }">
      <div class="loading-indicator" v-show="isLoading">Loading scheduling options...</div>
    </div>
  </div>
</template>

<script>
/* global Calendly */
import mixpanel from 'mixpanel-browser';

export default {
  name: 'MeetingScheduler',
  data() {
    return {
      calendlyHeight: '100%', // Initial height, will be dynamically adjusted
      calendlyWidth: '100%', // Dynamically adjust the width to use 100% of the container
      isLoading: true, // Add isLoading flag
    };
  },
  mounted() {
    this.loadCalendly();
    this.adjustSize();
    window.addEventListener('resize', this.adjustSize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.adjustSize);
  },
  methods: {
    loadCalendly() {
      const script = document.createElement('script');
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      document.body.appendChild(script);

      script.onload = () => {
        Calendly.initInlineWidget({
          url: 'https://calendly.com/deepvanceai/discovery-session',
          parentElement: document.getElementById('calendly'),
          prefill: {},
          utm: {}
        });
        this.isLoading = false; // Hide loading indicator once Calendly is initialized
        this.trackCalendlyEvent('Calendly Widget Loaded');
      };
    },
    adjustSize() {
      this.adjustHeight();
      this.adjustWidth();
    },
    adjustHeight() {
      //const availableHeight = window.innerHeight - this.calculateOffsetHeight();
      this.calendlyHeight = '100%';//`${availableHeight}px`;
    },
    adjustWidth() {
      // Assuming you want to dynamically adjust the width as well,
      // If there are specific offsets or calculations, add them here.
      this.calendlyWidth = '100%'; // This ensures the width is dynamically set to 100% of its container.
    },
    calculateOffsetHeight() {
      const headerHeight = 0;
      const footerHeight = 0;
      const additionalPadding = 120;
      return headerHeight + footerHeight + additionalPadding;
    },
    trackCalendlyEvent(action) {
      mixpanel.track('Meeting Scheduler Interaction', {
        action: action,
      });
    },
  },
};
</script>

<style scoped>
.meeting-scheduler {
  width: 100vw;
  height: 80vh;

  margin: 0 auto; /* Adjusted to remove padding and use margin auto for centering */
  box-shadow: none; /* Optional: Adjust or remove based on your design */
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20px;
  color: #999;
}

/* Ensure any global styles are not adding overflow or extra height to the page */
</style>
