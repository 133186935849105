<template>
  <div class="blog-container">
    <div class="blog-posts">
      <!-- Iterate over blog posts -->
      <div v-for="post in posts" :key="post.id" class="blog-post">
        <a :href="`/post/${post.id}`" class="post-title">{{ post.title }}</a>
        <p class="post-excerpt">{{ post.summary }}</p>
      </div>
    </div>
  </div>
</template>

  
  <script>
  export default {
    name: 'ViewBlog',
    computed: {
    posts() {
      return this.$store.state.posts;
    },
    },
  };
  </script>
  
  <style scoped>
  .blog-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 100px; /* Adjusted for consistent spacing with navbar */
    width: 100vw;

  }
  
  .blog-posts {
    align-items: center;

    width: 80%;
    max-width: 1200px; /* Aligned maximum width with your 'about-us' styling */
  }
  
  .blog-post {
    align-items: center;

    margin-bottom: 40px; /* Increased spacing for clarity */
    padding: 20px; /* Added padding for better content presentation */
    background-color: #fff; /* Consistent background for readability */
    border-radius: 20px; /* Rounded corners for visual consistency */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Soft shadow for depth */
    width: 80vw;
  }
  
  .post-title {
    align-items: center;

    color: #1976D2; /* Harmonized title color with your theme */
    text-decoration: none; /* Keeps underline removed */
    font-size: 28px; /* Consistent title size with your adjustments */
    font-weight: bold; /* Enhance visibility */
    margin-bottom: 10px; /* Added space between title and excerpt */
    transition: color 0.3s ease, transform 0.2s; /* Enhanced transitions */
  }
  
  .post-title:hover {
    color: #2196F3; /* Lighter shade on hover for interactive feel */
    transform: scale(1.05); /* Slight scale for engagement */
  }
  
  .post-excerpt {
    color: #666; /* Maintained excerpt color for readability */
    font-size: 18px; /* Adjusted size for consistency */
    line-height: 1.6; /* Improved readability */
  }
  </style>
  