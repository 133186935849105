<template>
  <div class="services-container">
    <div id="services-main-title">Our Services</div>
    <div class="service-navigation left-nav" @click="trackAndNavigate('previous')">&#8592;</div>
    <transition :name="transitionName" mode="out-in">
      <div class="service-display" :key="currentService.id">
        <div class="service-description">
          <h2 class="service-title">{{ currentService.title }}</h2>
          <p v-if="windowWidth > smallScreenWidth || isDescriptionExpanded">{{ currentService.description }}</p>
          <p v-else>{{ currentService.description.substring(0, 0) + '' }}</p>
          <button v-if="windowWidth <= smallScreenWidth && !isDescriptionExpanded" @click="toggleAndTrackDescription" class="read-more-btn">Read More</button>
        </div>
        <div class="service-image">
          <img :src="currentService.image" :alt="currentService.title" class="service-icon">
        </div>
      </div>
    </transition>
    <div class="service-navigation right-nav" @click="trackAndNavigate('next')">&#8594;</div>
    <div class="service-indicators">
      <span v-for="n in services.length" :key="n" class="indicator" :class="{ active: currentServiceIndex === n-1 }" @click="trackAndGoToService(n-1)"></span>
    </div>
  </div>
</template>


<script>
import mixpanel from 'mixpanel-browser';

export default {
  name: 'ViewServices',
  data() {
    return {
      services: [
                {
                  id: 1,
                  title: 'Custom AI Solutions Development',
                  image: '/images/services/solutions.webp',
                  description: 'Our team collaborates to design and deploy custom AI solutions, specializing in LLMs. We provide fine-tuning of pre-trained models, advanced image/video analysis, object detection, and RAG for enhanced decision-making.'
                },
                {
                  id: 2,
                  title: 'AI Training and Capability Building',
                  image: '/images/services/teaching.webp',
                  description: 'Empower your team with our AI training and capability building services. We offer customized training and workshops in AI technologies and models, tailored to your organization’s needs.'
                },
                {
                  id: 3,
                  title: 'Machine Learning and Optimization',
                  image: '/images/services/optimization.webp',
                  description:  'Optimize your processes with our machine learning and optimization solutions. We develop predictive models and optimization algorithms to solve complex business challenges.'
                },
                {
                  id: 4,
                  title: 'Data Science & Advanced Analytics',
                  image: '/images/services/analytics.webp',
                  description: 'Unlock the potential of your data with our analytics services. From data preparation to predictive modeling, our team enables you to make informed decisions and uncover insights.'
                },
                {
                  id: 5,
                  title: 'Software Engineering for AI Integration',
                  image: '/images/services/software.webp',
                  description:'Integrate AI seamlessly into your systems with our software engineering expertise. We specialize in enhancing your products and infrastructure with AI features and automation.'
                }
      ],
      currentServiceIndex: 0,
      autoCycleInterval: null,
      windowWidth: window.innerWidth,
      isDescriptionExpanded: false,
      smallScreenWidth: 768,
    };
  },
  mounted() {
    this.startAutoCycle();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    clearInterval(this.autoCycleInterval);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    trackAndNavigate(direction) {
      this.trackSpecificEvent('Service Navigation', direction);
      direction === 'next' ? this.nextService() : this.previousService();
    },
    trackAndGoToService(index) {
      this.trackSpecificEvent('Service Direct Select', `Service ${index + 1}`);
      this.goToService(index);
    },
    toggleAndTrackDescription() {
      this.trackSpecificEvent('Service Description Toggle', this.isDescriptionExpanded ? 'Collapse' : 'Expand');
      this.toggleDescription();
    },
    trackSpecificEvent(action, detail) {
      mixpanel.track('ViewServices Interaction', {
        action: action,
        detail: detail,
      });
    },
    nextService() {
      this.transitionName = 'slide-left';
      this.currentServiceIndex = (this.currentServiceIndex + 1) % this.services.length;
      this.resetAutoCycle();
    },
    previousService() {
      this.transitionName = 'slide-right';
      this.currentServiceIndex = (this.currentServiceIndex + this.services.length - 1) % this.services.length;
      this.resetAutoCycle();
    },
    goToService(index) {
      this.currentServiceIndex = index;
      this.resetAutoCycle();
    },
    startAutoCycle() {
      this.autoCycleInterval = setInterval(() => {
        this.nextService();
      }, 10000);
    },
    resetAutoCycle() {
      clearInterval(this.autoCycleInterval);
      this.startAutoCycle();
    },
    toggleDescription() {
      this.isDescriptionExpanded = !this.isDescriptionExpanded;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  computed: {
    currentService() {
      return this.services[this.currentServiceIndex];
    }
  }
};
</script>


<style scoped>


/* Enter and Leave Active Transitions */
.slide-left-enter-active, .slide-left-leave-active, .slide-right-enter-active, .slide-right-leave-active {
  transition: transform 0.5s ease;
}

/* Initial and Final States for Slide Left */
.slide-left-enter, .slide-left-leave-to {
  transform: translateX(-100%);
}
.slide-left-enter-to, .slide-left-leave {
  transform: translateX(0);
}

/* Initial and Final States for Slide Right */
.slide-right-enter, .slide-right-leave-to {
  transform: translateX(100%);
}
.slide-right-enter-to, .slide-right-leave {
  transform: translateX(0);
}



.read-more-btn {
  background-color: #0D47A1; /* Button color */
  color: #ffffff; /* Text color */
  border: none;
  padding: 10px 20px;
  margin-top: 10px; /* Space above the button */
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px; /* Adjust as needed */
}
.read-more-btn:hover {
  background-color: #1976D2; /* Darker shade on hover */
}

#services-main-title {
  position: absolute;
  top: 30px; /* Positioned 30px below the start of the container */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for exact centering */
  font-weight: bold; /* Make it bold for emphasis */
  font-size: 3rem; /* Retained font size for impact */
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Sleek and modern font */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Aesthetic font */

  background: -webkit-linear-gradient(45deg, #3787e9, #031F41); /* Soft gradient from pink to blue */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Applying the gradient to the text */
  text-shadow: 2px 2px 8px rgba(0,0,0,0.2); /* Smoother shadow for depth */  padding: 10px 20px; /* Add some padding for better presentation */
  border-radius: 5px; /* Optional: add rounded corners for a modern look */
  text-align: center; /* Ensure the text is centered within the div */
}


.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Adjusted to flex-start to respect the absolute positioning of the title */
  background-image: #ffffff;
  color: #ffffff;
  padding: 70px 20px 20px; /* Increase top padding to accommodate the title */
  margin: auto;
  min-height: 500px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}



/* Adjustments for the service display container to ensure it remains centered */
.service-display {
  width: 80%; /* Adjust based on your layout needs */
  max-width: 1200px; /* Ensuring the content is not too stretched on wider screens */
  margin: auto; /* Centering within the available space */
  display: flex;
  flex-direction: row; /* Assuming a row-based layout for the image and text */
  align-items: center; /* Vertically aligning the children */
  justify-content: center; /* Horizontally centering the content */
  gap: 20px; /* Spacing between the image and description */
}

/* Additional responsiveness adjustments */
@media screen and (max-width: 768px) {
  .service-display {
    flex-direction: column; /* Stack image and text vertically on smaller screens */
  }

  .services-container {
    padding: 180px 0px; /* Adjusted padding for smaller screens */
  }
}


.service-description, .service-image {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content for both description and image */
  align-items: center; /* This will center-align the items for the image */
  text-align: center; /* Center-align text for the description */
  padding: 0 20px;
  max-width: 80vw;
  color: #666;
}
.service-image {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content for both description and image */
  align-items: center; /* This will center-align the items for the image */
  text-align: center; /* Center-align text for the description */
  padding: 0 20px;
  max-width: 80vw;
  color: #666;
}
.service-title, .service-description p {
  margin: 0; /* Remove default margins and adjust as necessary */
  max-width: 80vw;

}

.service-icon {
  max-width: 80vw;
  max-height: 300px;
  object-fit: cover;
  border-radius: 10px;
  /* Remove margin here if you want to control spacing through gap in .service-display */
}

.service-title {
  margin: 0 0 10px 0; /* Add some margin below the title for spacing */
  color: #031F41; /* Or any color that fits your design */
  font-size: 24px; /* Adjust the font size as needed */
}

.service-icon {
  max-width: 100%;
  max-height: 300px; /* Adjust based on your requirement */
  object-fit: cover; /* Ensure the aspect ratio is maintained */
  border-radius: 10px;
  margin: 0 20px; /* Adjust margin for proper spacing */
}
.service-indicators {
  display: flex;
  justify-content: center; /* Center the indicators horizontally */
  align-items: center; /* Align items vertically */
  gap: 10px;
  padding: 20px;
  position: absolute;
  bottom: 10px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
}

.left-nav, .right-nav {
  position: absolute;
  top: auto; /* Adjust as needed */
  bottom: 10px; /* Align with service indicators */
  transform: translateY(0); /* Adjust as necessary */
  background-color: #ffffff;
  color: #1976D2;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 24px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
}

.left-nav {
  transform: translateX(calc(-100% - 90px)); /* Adjust distance from service indicators */
}

.right-nav {
  transform: translateX(calc(100% + 90px)); /* Adjust distance from service indicators */
}

.indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #bbb;
  cursor: pointer;
}

.indicator.active {
  background-color: #031F41;
}


</style>
