<template>
    <form @submit.prevent="submitForm">
      <div>
        <label for="name">Name:</label>
        <input id="name" v-model="form.name" type="text" required>
      </div>
      <div>
        <label for="email">Email:</label>
        <input id="email" v-model="form.email" type="email" required>
      </div>
      <div>
        <label for="message">Message:</label>
        <textarea id="message" v-model="form.message" required></textarea>
      </div>
      <button type="submit">Submit</button>
    </form>
  </template>


<script>
import emailjs from 'emailjs-com';
import mixpanel from 'mixpanel-browser';
export default {
  name: 'ContactForm',
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: 'Hi there! I wanted to know more about..',
      },
    };
  },
  methods: {
    submitForm() {
      console.log(this.form);
      emailjs.send('service_o47m40r', 'template_9v65xev', this.form, 'u7QCUpLxNfJrcjloq')
        .then(response => {
          console.log('SUCCESS!', response.status, response.text);
          alert('Your message has been sent successfully!');
          this.trackFormSubmission('Success', this.form);
        }, error => {
          console.log('FAILED...', error);
          alert('Failed to send the message, please try again.');
          this.trackFormSubmission('Failure', this.form);
        });
    },
    trackFormSubmission(status, formData) {
      mixpanel.track('Contact Form Submission', {
        'status': status,
        'name': formData.name,
        'email': formData.email,
        // Consider privacy practices before tracking sensitive or personal information
      });
    },
  },
};
</script>

<style scoped>

  form {
    background: var(--primary-color);
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 100vw;
    width: 60vw;
  }

  div {
    margin-bottom: 20px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
  }

  input, textarea {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid var(--accent-color);
    background-color: var(--form-background-color);
    color: var(--text-color);
    resize: none;
  }

  button {
    background-color: var(--button-color);
    color: var(--form-background-color);
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }



  /* Responsive adjustments */
  @media (max-width: 768px) {
    form {
      width: 90%;
    }
  }

  input, textarea {
    border: 1px solid #ced4da; /* More visible borders */
    background-color: #ffffff; /* White background */
  }

  input:focus, textarea:focus {
    outline: none;
    border-color: #80bdff; /* Highlight color on focus */
    background-color: #e9ecef; /* Light grey background on focus */
  }

  button {
    background-color: #007bff;
    color: #ffffff;
  }

  button:hover {
    background-color: #0056b3; /* Darker on hover */
    color: #ffffff;
    border-color: #0056b3;
  }
</style>
