<template>
    <div v-if="!isClosed">
      <div v-if="isMinimized" @click="toggleMinimize">
        <!-- Bubble for Opening Chat -->
        <img src="/icons/messages.webp" alt="Chat Bubble" />
      </div>
      <div v-else class="chatbox">
        <!-- Chat Header -->
        <div class="chat-header">
          <button @click="toggleMinimize">Minimize</button>
          <button @click="closeChat">X</button>
        </div>

        <!-- Messages Container -->
        <div ref="messagesContainer" class="messages-container">
          <div v-for="message in messages" :key="message.id" class="message" :class="{'user-message': message.isUser, 'bot-message': !message.isUser}">
            {{ message.text }}
          </div>
        </div>

        <!-- Input for New Message -->
        <div class="message-input">
          <input v-model="newMessage" @keyup.enter="sendMessage" placeholder="Type your message..." />
          <button @click="sendMessage">Send</button>
        </div>
      </div>
    </div>
  </template>

  <script>


  export default {
    data() {
      return {
        isMinimized: true, // Start minimized
        isClosed: false,
        newMessage: '',
        messages: [
          // Sample messages
          { id: 1, text: 'Hello! How can I help you?', isUser: false },
        ],
      };
    },

    methods: {
      toggleMinimize() {
        this.isMinimized = !this.isMinimized;
      },
      closeChat() {
        this.isClosed = true;
      },

      async getGptResponse(messages) {
        try {
          const response = await fetch('https://backend-nuestra-web-3541c3056b46.herokuapp.com/chat', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ messages: messages }),
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          console.log(data)
          console.log("Data fetched successfully:", data.result);
          return data.result; // Return the result so it can be used by the caller
        } catch (error) {
          console.error("Error fetching data:", error);
          throw error; // Re-throw the error to be handled by the caller
        }
      },

      async sendMessage() {
      if (!this.newMessage.trim()) return;

      const userMessage = this.newMessage;
      this.newMessage = ''; // Reset input before the request

      // Add user message to chat history
      this.messages.push({
        id: Date.now(),
        text: userMessage,
        isUser: true,
      });

      // Prepare messages for the API request
      const conversationHistory = this.messages.map(msg => ({
        role: msg.isUser ? 'user' : 'assistant',
        content: msg.text,
      }));

      try {
        const gptResponse = await this.getGptResponse(conversationHistory);

        // Add GPT response to chat
        this.messages.push({
          id: Date.now(),
          text: gptResponse,
          isUser: false,
        });
      } catch (error) {
        console.error('Error:', error);
      }

      // Ensure new messages are scrolled into view
      this.$nextTick(() => {
        const container = this.$refs.messagesContainer;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },
    },
  };
  </script>


  <style scoped>
  .chatbox {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 25vw;
    height: 50vh;
    min-width: 380px;
    min-height: 300px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    border-radius: 8px;
    overflow: hidden;
    z-index: 10000;
  }

  .chat-header {
    padding: 10px;
    background-color: #007bff;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .messages-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  background: #e5e5ea;
  display: flex;
  flex-direction: column; /* Ensures messages are laid out in a vertical stack */
}

.message {
  margin-bottom: 10px;
  padding: 8px 10px;
  border-radius: 20px;
  max-width: 70%;
  align-self: flex-start; /* Default alignment to the left */
}

.user-message {
  background-color: #007bff; /* Blue bubble for user messages */
  color: #ffffff;
  align-self: flex-end; /* Align user messages to the right */
}

.bot-message {
  background-color: #4CAF50; /* Green bubble for bot messages */
  color: #ffffff;
  /* No need for 'align-self' here since it's aligned to the left by default */
}

  .message-input {
    display: flex;
    padding: 10px;
    background-color: #ffffff;
    border-top: 1px solid #ccc;
  }

  .message-input input {
    flex-grow: 1;
    border: 1px solid #ccc;
    border-radius: 18px;
    padding: 8px 15px;
    margin-right: 8px;
  }

  .message-input button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 18px;
    cursor: pointer;
  }

  /* Bubble Icon */
  img {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 10vw;
    height: 10vw;
    max-width: 70px;
    max-height: 70px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }
  @keyframes vibrate {
  0% { transform: translate(0); }
  20% { transform: translate(-2px, 2px); }
  40% { transform: translate(-2px, -2px); }
  60% { transform: translate(2px, 2px); }
  80% { transform: translate(2px, -2px); }
  100% { transform: translate(0); }
}
.vibrate {
  animation: vibrate 0.5s linear infinite;
}
  </style>
