<template>
  <div class="contact-us">
    <h2 class="contact-title">Let's Connect!</h2>
    <p class="contact-subtitle">We're excited to hear from you. Fill out the form below, and let's start the conversation.</p>
    <contact-form></contact-form>
    <!-- Additional contact information can go here -->
  </div>
</template>

  <script>
  import ContactForm from '../components/ContactForm.vue';

  export default {
    name: 'ContactUs',
    components: {
      ContactForm
    }
  };
  </script>
<style scoped>
/* Variables for easy theme adjustments - adjusted some colors for better contrast and appeal */
:root {
  --primary-color: #4A56E2; /* Updated to a more vibrant dark blue */
  --accent-color: #957DAD; /* Updated for better visibility */
  --text-color: #FEFEFE; /* Brighter off-white for better readability */
  --form-background-color: #22223b; /* Kept the very dark blue for contrast */
  --button-color: #F4BFBF; /* A warmer soft pink for the button */
}

.contact-us {
  display: flex;
  flex-direction: column; /* Changed to column for better structure */
  justify-content: center;
  align-items: center;
  min-width: 80vw;
  min-height: 80vh;
  background-color: var(--form-background-color);
  color: var(--text-color);
  padding: 200px 20px 20px 20px; /* Adjusted top padding */
  margin-top: var(--navbar-height)+50px;
  border-radius: 10px; /* Added for a softer look */
  width: 100vw;
}

.contact-title, .contact-subtitle {
  text-align: center;
  margin-bottom: 20px; /* Spacing before the form */
}

.contact-title {
  font-weight: bold;
  font-size: 3rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Aesthetic font */

  background: -webkit-linear-gradient(45deg, #3787e9, #031F41);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 8px rgba(0,0,0,0.2);
  padding: 2vw 4vw;
  border-radius: 5px;
  text-align: center;
  z-index: 1; /* Ensure title is above logos */
}

/* Responsive adjustments for better mobile viewing */
@media (max-width: 768px) {
  .contact-us {
    min-width: 90vw; /* Slightly wider on smaller screens for more room */
    padding-top: 50px; /* Adjusted for mobile layout */
  }
}
</style>