<template>
    <div class="product-container">
    <h2 class="mainproduct-title">Explore our amazing products</h2>
    <p class="mainproduct-subtitle">Currently, our offerings are limited to the nomenclature product. We plan to expand our selection shortly. Thank you for your patience as we continue to develop our product line.</p>
    
      <div class="product-list">
        <!-- Iterate over products -->
        <div v-for="product in products" :key="product.id" class="product-item">
          <a :href="`/product/${ product.name }`" class="product-title">{{ product.title }}</a>
          <p class="product-summary">{{ product.summary }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ViewProducts',
    computed: {
      products() {
        return [
          { id: 1, title: "Automated Nomenclature Classification for Import Items with Ambiguity Resolution",
           summary: "This tool automatically categorizes import items into their correct nomenclature, offering explanations and multiple options for ambiguous cases. It simplifies customs compliance by using advanced algorithms to match descriptions with international classification systems, improving accuracy and efficiency in global trade operations.",
            name:"nomenclature" },
        ];
      },
    },
  };
  </script>
    
  <style scoped>
  .product-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 100px; /* Adjusted for consistent spacing with navbar */
    width: 100vw;
  }
  
  .product-list {
    width: 100%;
    width: 80vw;

  }
  
  .product-item {
    margin-bottom: 40px; /* Increased spacing for clarity */
    padding: 20px; /* Added padding for better content presentation */
    background-color: #fff; /* Consistent background for readability */
    border-radius: 20px; /* Rounded corners for visual consistency */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Soft shadow for depth */
  }
  
  .product-title {
    color: #1976D2; /* Harmonized title color with your theme */
    text-decoration: none; /* Keeps underline removed */
    font-size: 28px; /* Consistent title size with your adjustments */
    font-weight: bold; /* Enhance visibility */
    margin-bottom: 10px; /* Added space between title and summary */
    transition: color 0.3s ease, transform 0.2s; /* Enhanced transitions */
  }
  
  .product-title:hover {
    color: #2196F3; /* Lighter shade on hover for interactive feel */
    transform: scale(1.05); /* Slight scale for engagement */
  }
  
  .product-summary {
    color: #666; /* Maintained summary color for readability */
    font-size: 18px; /* Adjusted size for consistency */
    line-height: 1.6; /* Improved readability */
  }


.mainproduct-title, .mainproduct-subtitle {
  text-align: center;
  margin-bottom: 20px; /* Spacing before the form */
}

.mainproduct-title {
  font-weight: bold;
  font-size: 3rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Aesthetic font */

  background: -webkit-linear-gradient(45deg, #3787e9, #031F41);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 8px rgba(0,0,0,0.2);
  padding: 2vw 4vw;
  border-radius: 5px;
  text-align: center;
  z-index: 1; /* Ensure title is above logos */
}

  </style>
  